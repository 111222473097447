export const BASE_URL  = "https://be-escort.angeboutchoue.com/api";

//export const BASE_URL = "https://esc-app-01851ba16142.herokuapp.com/api"

//export const BASE_URL  = "http://localhost:1725/api";

export const BASE_FILE_UPLOAD_URL = "https://file.angeboutchoue.com/api";

//export const BASE_FILE_UPLOAD_URL = "http://localhost:1717/api";


