// IMPORT DEPENDENCIES & CONFIG
import Decode from 'jwt-decode';

// IMPORT URLS 
import { LOGIN_ADMIN_URL } from '../../urls/auth/auth';

// IMPORT TYPES
import { LOGIN_ADMIN_START, LOGIN_ADMIN_SUCCESS, LOGIN_ADMIN_FAILED, LOGOUT } from '../../types/auth/auth';

export const loginAdminStart = () => {
    return {
        type: LOGIN_ADMIN_START
    }
}

export const loginAdminSuccess = (token, name, role) => {
    return {
        type: LOGIN_ADMIN_SUCCESS,
        token: token,
        name: name,
        role: role
    }
}

export const loginAdminFail = (error) => {
    return {
        type: LOGIN_ADMIN_FAILED,
        error: error
    }
}

//expiration date
export const checkLoginTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut());
        }, expirationTime)
    }
}

export const logOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    return {
        type: LOGOUT
    }
}

export const loginAdminService = (email, password) => {

    console.log(LOGIN_ADMIN_URL)
       
    return dispatch => {
        dispatch(loginAdminStart())
        fetch(LOGIN_ADMIN_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                "username": `${email}`,
                "password": `${password}`,
            })
        })
     
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    var decoded = Decode(response.data.response)
                    console.log('Decodedd===', decoded);
                    const expirationDate = new Date(new Date().getTime() + decoded.exp * 1000)
                    localStorage.setItem('token', response.data.response);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('username', "Admin");
                    localStorage.setItem('role', "admin");
                    dispatch(loginAdminSuccess(response.data.response, decoded.userdata[0].email, "admin"));
                    dispatch(checkLoginTimeOut(decoded.exp));
                }
                else {
                    console.log(response)
                    dispatch(loginAdminFail(response.responseMessage))
                }
            })
            .catch(error => {
                console.log("=====>>>", error.message)
                dispatch(loginAdminFail(error.message))
            })
    }
}

export const loginCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const role = localStorage.getItem('role');
        if (!token) {
            dispatch(logOut())
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logOut())
            }
            else {
                dispatch(loginAdminSuccess(token, username, role))
                dispatch(checkLoginTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}
