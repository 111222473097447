import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getIlanService, setVipStatusService, openDeleteIlanModal, closeDeleteIlanModal, deleteIlanService,
  openUpdateIlanModal, closeUpdateIlanModal, updateIlanService, getSemtService, uploadUrl1FileService,
  uploadUrl2FileService, uploadUrl3FileService, uploadUrl4FileService, setVesikaletStatusService
} from '../../actions/home/home';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import Switch from "react-switch";
import RichTextEditor from 'react-rte';

import Select from 'react-select';



import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";




const gorusmeyerioptions = [
  { value: 'ev', label: 'ev' },
  { value: 'otel', label: 'otel' },
  { value: 'rezidans', label: 'rezidans' },
];



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',

  },
};


Modal.setAppElement('#root');

const IlanList = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const ilan = useSelector(state => state.HomeReducer.ilan);
  const loadingilan = useSelector(state => state.HomeReducer.loadingilan);
  const deleteilanmodal = useSelector(state => state.HomeReducer.deleteilanmodal);
  const loadingdeleteilan = useSelector(state => state.HomeReducer.loadingdeleteilan);
  const updateilanmodal = useSelector(state => state.HomeReducer.updateilanmodal);
  const loadingupdateilan = useSelector(state => state.HomeReducer.loadingupdateilan);
  const url1 = useSelector(state => state.HomeReducer.url1);
  const url2 = useSelector(state => state.HomeReducer.url2);
  const url3 = useSelector(state => state.HomeReducer.url3);
  const url4 = useSelector(state => state.HomeReducer.url4);


  const [ilanData, setIlanData] = useState([])
  const [text, setText] = useState(RichTextEditor.createEmptyValue());
  const [selectedOption, setSelectedOption] = useState(null);
  const [ilanBasligi, setIlanBasligi] = useState("");
  const [isim, setIsim] = useState("");
  const [sira, setSira] = useState(null);
  const [telefon, setTelefon] = useState("");
  const [whatssap, setWhatssap] = useState("");
  const [telegram, setTelegram] = useState("");
  const [yas, setYas] = useState(null);
  const [gorusme, setGorusme] = useState(null);
  const [zaman, setZaman] = useState(null);
  const [bilgi, setBilgi] = useState(null);
  const [error, setError] = useState(false);
  const [selectedFileUrl1, setSelectedFileUrl1] = useState(null);
  const [selectedFileUrl2, setSelectedFileUrl2] = useState(null);
  const [selectedFileUrl3, setSelectedFileUrl3] = useState(null);
  const [selectedFileUrl4, setSelectedFileUrl4] = useState(null);


  useEffect(() => {
    dispatch(getIlanService(token))
  }, []);

  const handleSwitch = (switchstatus, item) => {
    dispatch(setVipStatusService(item.id, !switchstatus, token))
  }

  const handleSwitchVesikalet = (switchstatus, item) => {
    dispatch(setVesikaletStatusService(item.id, !switchstatus, token))
  }

  const openConfirmDelete = (item) => {
    setIlanData(item)
    dispatch(openDeleteIlanModal())

  }

  const closeModal = () => {
    dispatch(closeDeleteIlanModal())
  }

  const handleEvetDelete = () => {
    dispatch(deleteIlanService(ilanData.id, token))
  }

  const closeModalUpdate = () => {
    dispatch(closeUpdateIlanModal())
  }

  const openEditIlan = (item) => {
    setIlanData(item)
    setIlanBasligi(item.title)
    setSelectedOption(item.contactlieu)
    setIsim(item.name)
    setTelefon(item.telephone)
    setWhatssap(item.whatssap)
    setTelegram(item.telegram)
    setYas(item.yas)
    setZaman(item.zaman)
    setBilgi(item.bilgi)

    dispatch(openUpdateIlanModal())
  }



  const handleSemt = (e) => {
    setSelectedOption(e)
    console.log(`Option selected:`, e)
  }


  const handleText = (value) => {
    setText(value)
    value.toString('html')
  }


  const handleIlanBasligi = (e) => {
    setIlanBasligi(e.target.value)
    setError(false)
  }

  const handleIsim = (e) => {
    setIsim(e.target.value)
    setError(false)
  }

  const handleSira = (e) => {
    setSira(e.target.value)
    setError(false)
  }

  const handleTelefon = (e) => {
    setTelefon(e.target.value)
    setError(false)
  }

  const handleWhatssap = (e) => {
    setWhatssap(e.target.value)
    setError(false)
  }

  const handleTelegram = (e) => {
    setTelegram(e.target.value)
    setError(false)
  }

  const handleYas = (e) => {
    setYas(e.target.value)
    setError(false)
  }

  const handleGorumeYer = (e) => {
    setGorusme(e)
    setError(false)
  }

  const handleBilgi = (e) => {
    setBilgi(e.target.value)
    setError(false)
  }

  const handleFileUrl1Upload = (event) => {
    setSelectedFileUrl1(event.target.files[0]);
    dispatch(uploadUrl1FileService(event.target.files[0]))
  };

  const handleFileUrl2Upload = (event) => {
    setSelectedFileUrl2(event.target.files[0]);
    dispatch(uploadUrl2FileService(event.target.files[0]))
  };


  const handleFileUrl3Upload = (event) => {
    setSelectedFileUrl3(event.target.files[0]);
    dispatch(uploadUrl3FileService(event.target.files[0]))
  };

  const handleFileUrl4Upload = (event) => {
    setSelectedFileUrl4(event.target.files[0]);
    dispatch(uploadUrl4FileService(event.target.files[0]))
  };


  const handleZaman = (e) => {
    setZaman(e.target.value)
  }



  const handleEvetUpdate = (e) => {
    e.preventDefault();
    let gorus = ""

    gorusme.map((item) => {
      gorus = gorus + item.value + ' - '
    })

    if (ilanBasligi !== "" && ilanBasligi.trim() !== "" && text !== "" && selectedOption !== null && isim !== "" && isim.trim() !== "" 
      && telefon !== "" && telefon.trim() !== "" && whatssap !== "" && whatssap.trim() !== "" && telegram !== "" && telegram.trim() !== "" && yas !== null  && gorusme !== ""
      !== "" && zaman !== ""  && bilgi !== "" && bilgi.trim() !== "" && url1 !== "" && url1.trim() !== "" && url2 !== "" && url2.trim() !== "" && url3 !== "" && url3.trim() !== "" &&
      url4 !== "" && url4.trim() !== "") {

      dispatch(updateIlanService(ilanData.id, ilanBasligi, isim, telefon, whatssap, gorus, telegram, Number(yas), bilgi, Number(zaman), text.toString('html'), url1, url2, url3, url4,ilanData.semt.id, token))
    }
    else {
      setError(true)
    }
  };


  let IlanList = ilan.map((item, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td> <img style={{ width: 40, height: 40, borderRadius: 50 }} src={item.url1} /></td>
        <td> <Switch onChange={() => handleSwitchVesikalet(item.vesikalet, item)} checked={item.vesikalet} /></td>
        <td>{item.title}</td>
        <td>{item.name}</td>
        <td>{item.semt.name}</td>
        <td>{item.whatssap}</td>
        <td> <Switch onChange={() => handleSwitch(item.show, item)} checked={item.show} /></td>
        <td>{item.contactlieu}</td>
        <td>{item.yas}</td>
        <td>{item.zaman} gün</td>
        <td>
          <ul class="list-inline applicant-list-actions">
            <li class="list-inline-item">
              <a href="#" class="text-muted">
                <i
                  onClick={() => {
                    openEditIlan(item);
                  }}
                  class="mdi mdi-border-color edit-pen"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a class="text-custom">
                <i
                  onClick={() => {
                    openConfirmDelete(item);
                  }}
                  class="mdi mdi-delete delete-box"
                ></i>
              </a>
            </li>
          </ul>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div id="wrapper">
        <Header />
        <Navbar />
        <div class="content-page">
          <div class="content">
            <div class="input-group-append btn-go-back">
              <Link to="/">
                <button class="btn" type="submit">
                  <i class="mdi mdi-arrow-left"></i>
                </button>
              </Link>
            </div>

            <div class="input-group-append btn-go-back">
              <Link to="/">
                <button class="btn" type="submit">
                  <i class="mdi mdi-arrow-left"></i>
                </button>
              </Link>
            </div>

            <div class="container-fluid">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Resim</th>
                      <th>Vesikalet</th>
                      <th>İlan başliği</th>
                      <th>İsim</th>
                      <th>Semt</th>
                      <th>Whatssap</th>
                      <th>Göster</th>
                      <th>Görüşme yeri</th>
                      <th>Yaş</th>
                      <th>Ilan Suresi</th>
                      <th>Aksyon</th>
                    </tr>
                  </thead>
                  <tbody className="set-relative">
                    {loadingilan ?
                      <div className="loader-parent">
                        <Loader />
                      </div>
                      : IlanList
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={deleteilanmodal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 500, height: 100, padding: 0, marginTop: -20 }}>
          <h2>Ilan Silmek</h2>
          <button onClick={closeModal}>X</button>
        </div>

        <div>
          <p> o ilan silmek istiyor musun ?</p>
        </div>

        <div>
          {
            loadingdeleteilan ?
              <div className="loader-parent">
                <Loader />
              </div>
              :
              <div
                onClick={handleEvetDelete}
                class="btn btn-primary"
                style={{ marginTop: 10 }}
              >
                Evet
              </div>
          }

        </div>

      </Modal>

      <Modal
        isOpen={updateilanmodal}
        onRequestClose={closeModalUpdate}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 500, height: 100, padding: 0 }}>
          <h2>Ilan Güncellemek</h2>
          <button onClick={closeModalUpdate}>X</button>
        </div>

        <div>
          {/* <div class="form-group col-md-6">
            <label for="inputAddress2" class="col-form-label">
              semt Adı
            </label>
            <input
              type="text"
              value={name}
              onChange={handleName}
              class="form-control"
              id=""
              placeholder="Semt Adı"
            />
          </div> */}


          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4" class="col-form-label">
                İlan başliği{" "}
              </label>
              <input
                type="text"
                value={ilanBasligi}
                onChange={handleIlanBasligi}
                class="form-control"
                placeholder="İlan Başliği"
              />
            </div>
            <div class="form-group col-md-3">
              <label for="inputEmail4" class="col-form-label">
                İsim
              </label>
              <input
                type="text"
                value={isim}
                onChange={handleIsim}
                class="form-control"
                placeholder="İsim"
              />
            </div>
          </div>

          <div class="form-row">
            {/* <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Sira
              </label>
              <input
                type="number"
                value={sira}
                onChange={handleSira}
                class="form-control"
                id=""
                placeholder="Sira"
              />
            </div> */}

            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Telefon
              </label>
              <input
                type="text"
                value={telefon}
                onChange={handleTelefon}
                class="form-control"
                id=""
                placeholder="Telefon"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="" class="col-form-label">
                Whatssap
              </label>
              <input
                type="text"
                value={whatssap}
                onChange={handleWhatssap}
                class="form-control"
                id="inputAddress2"
                placeholder="Whatssap"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Telegram
              </label>
              <input
                type="text"
                value={telegram}
                onChange={handleTelegram}
                class="form-control"
                id=""
                placeholder="Telegram"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Yaş
              </label>
              <input
                type="number"
                value={yas}
                onChange={handleYas}
                class="form-control"
                id=""
                placeholder="Yaş"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="" class="col-form-label">
                Gorüşme yeri
              </label>
              <Select
                value={gorusme}
                onChange={handleGorumeYer}
                options={gorusmeyerioptions}
                isMulti
                class="form-control"
              />
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Bilgi
              </label>
              <input
                type="text"
                value={bilgi}
                onChange={handleBilgi}
                class="form-control"
                placeholder="Bilgi"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Url
              </label>
              {

              }
              <input
                type="file"
                onChange={handleFileUrl1Upload}
                class="form-control"
                id="file"
                placeholder="Resim Ekle"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Url2
              </label>
              <input
                type="file"
                onChange={handleFileUrl2Upload}
                class="form-control"
                id=""
                placeholder="Resim Ekle"
              />
            </div>
          </div>

          <div class="form-row">

            <div class="form-group col-md-4">
              <label for="inputAddress2" class="col-form-label">
                Url3
              </label>
              <input
                type="file"
                onChange={handleFileUrl3Upload}
                class="form-control"
                id=""
                placeholder="Resim Ekle"
              />
            </div>

            <div class="form-group col-md-4">
              <label for="inputAddress2" class="col-form-label">
                Url4
              </label>
              <input
                type="file"
                onChange={handleFileUrl4Upload}
                class="form-control"
                id=""
                placeholder="Resim Ekle"
              />
            </div>

            <div class="form-group col-md-3">
              <label for="inputAddress2" class="col-form-label">
                Ilan suresi
              </label>
              <input
                type="number"
                value={zaman}
                onChange={handleZaman}
                class="form-control"
                id=""
                placeholder="İlan suresi"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-9">
              <label for="inputAddress2" class="col-form-label">
                İlan detayları
              </label>
              <RichTextEditor
                value={text}
                onChange={handleText}
              />
            </div>
          </div>


        </div>

        <div>
          {
            loadingupdateilan ?
              <div className="loader-parent">
                <Loader />
              </div>
              :
              <div
                onClick={handleEvetUpdate}
                class="btn btn-primary"
                style={{ marginTop: 10 }}
              >
                Guncelle

              </div>

          }

          {error ? (
            <p style={{ color: "red" }}>
              Bilgileri kontrol edin
            </p>
          ) : null}

        </div>

      </Modal>
      <Footer />
    </>
  );
};

export default IlanList;
