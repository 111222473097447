import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { getIletisimService, addIletisimService, setIletisimText } from "../../actions/home/home";
import { Redirect } from "react-router-dom";
import RichTextEditor from 'react-rte';


import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const Iletisim = () => {

    const Dispatch = useDispatch();

    useEffect(() => {
        Dispatch(getIletisimService(token))
    }, []);


    const token = useSelector(state => state.AuthReducer.token);
    const loadingiletisim = useSelector(state => state.HomeReducer.loadingiletisim);
    const iletisimtext = useSelector(state => state.HomeReducer.iletisimtext);
    const addiletisimstatue = useSelector(state => state.HomeReducer.addiletisimstatue);

    const [error, setError] = useState(false);
    //const [text, setText] = useState(RichTextEditor.createValueFromString(iletisimdata.iletisimdetail, "html"));


    const handleText = (value) => {
        Dispatch(setIletisimText(value))
        //setText(value.toString('html'))    
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (iletisimtext !== "") {
            Dispatch(addIletisimService(iletisimtext.toString('html'), token))
        }
        else {
            setError(true)
        }
    };

    let redirect = null;

    if (addiletisimstatue) {
        redirect = <Redirect to='/' />
    }

    return (
        <div id="wrapper">
            {redirect}
            <Header />
            <Navbar />
            <div className="content-page">
                <div className="content">
                    <div className="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-box">
                                    <h4 class="m-t-0 header-title">İletişim Olüşturmak</h4>
                                    <p class="text-muted mb-3 font-13">

                                    </p>
                                    {
                                        loadingiletisim ? <Loader /> :
                                            <form onSubmit={handleSubmit}>
                                                <div class="form-row">
                                                    <div class="form-group col-md-9">
                                                        <label for="inputAddress2" class="col-form-label">
                                                            İletişim
                                                        </label>
                                                        <RichTextEditor
                                                            value={iletisimtext}
                                                            onChange={handleText}
                                                        />
                                                    </div>
                                                </div>

                                                {error ? (
                                                    <p style={{ color: "red" }}>
                                                        Bilgileri kontrol edin
                                                    </p>
                                                ) : null}
                                                {loadingiletisim ? (
                                                    <Loader />
                                                ) :

                                                    <button
                                                        type="submit"
                                                        class="btn btn-primary"
                                                        style={{ marginTop: 10 }}
                                                    >
                                                        Ekle
                                                    </button>
                                                }
                                            </form>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
};

export default Iletisim;

