
import React, { useEffect } from 'react'

//IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckState } from './actions/auth/auth';


import Login from "./pages/authentication/login";
import Panel from "./pages/home/panel";
import AddSemt from './pages/semt/addsemt';
import GetSemt from './pages/semt/getsemt';
import AddIlan from './pages/ilan/addilan';
import GetIlan from './pages/ilan/getilan';
import Iletisim from './pages/iletisim/iletiisim';



const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const role = useSelector(state => state.AuthReducer.role);

  useEffect(() => {
    dispatch(loginCheckState());
  }, []);

  let route = (
    <Switch>
      <Route path="/" exact component={Login} />
      <Redirect to='/' />
    </Switch>
  )

  if (token && (role === 'admin')) {
    route = (
      <>
        <Switch>
          <Route path="/iletisim" exact component={Iletisim} />
          <Route path="/get-ilan" exact component={GetIlan} />
          <Route path="/add-ilan" component={AddIlan} />
          <Route path="/get-semt" exact component={GetSemt} />
          <Route path="/add-semt" component={AddSemt} />
          <Route path="/dashboard" exact component={Panel} />
          <Redirect to='/dashboard' />
        </Switch>
      </>
    )
  }

  return (
    <div>
      {route}
    </div>
  );
}

export default withRouter(App);
