import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import Decode from 'jwt-decode'
import { getHomeDataService } from '../../actions/home/home'

import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Loader from "../../components/loader/loader";


const Panel = () => {

    const dispatch = useDispatch()

    const token = useSelector(state => state.AuthReducer.token);
    const semtcount = useSelector(state => state.HomeReducer.semtcount);
    const loadingcount = useSelector(state => state.HomeReducer.loadingcount);
    const ilancount = useSelector(state => state.HomeReducer.ilancount);

    useEffect(() => {
        dispatch(getHomeDataService(token))
      }, []);

    return (
        <>
            <div id="wrapper">
                <Header />
                <Navbar />
                <div className="content-page">
                    <div className="content">

                        {
                            loadingcount ?
                                <div className="loader-parent height-full">
                                    <Loader />
                                </div> :
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className="col-xl-3 col-md-6">
                                            <div className="card-box">
                                                <div className="dropdown float-right">
                                                    <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="javascript:void(0);" className="dropdown-item">List</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Semt ekle</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Yenile</a>
                                                    </div>
                                                </div>
                                                <h4 className="header-title mt-0 mb-3">Semt</h4>
                                                <div className="widget-box-2">
                                                    <div className="widget-detail-2 text-right">
                                                        <span className="badge badge-warning badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                        <h2 className="font-weight-normal mb-1"> {semtcount} </h2>
                                                        <p className="text-muted mb-3">Semt Kayitıtlı</p>
                                                    </div>
                                                    <div className="progress progress-bar-alt-success progress-sm">
                                                        <div className="progress-bar bg-warning" role="progressbar"
                                                            aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "77%" }}>
                                                            <span className="sr-only">77% Complete</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <div className="card-box">
                                                <div className="dropdown float-right">
                                                    <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="javascript:void(0);" className="dropdown-item">İlan listesi</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">İlan ekle</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Yenile</a>
                                                    </div>
                                                </div>

                                                <h4 className="header-title mt-0 mb-3">İlan</h4>
                                                <div className="widget-box-2">
                                                    <div className="widget-detail-2 text-right">
                                                        <span className="badge badge-success badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                        <h2 className="font-weight-normal mb-1"> {ilancount} </h2>
                                                        <p className="text-muted mb-3">Ilan kayitlı</p>
                                                    </div>
                                                    <div className="progress progress-bar-alt-success progress-sm">
                                                        <div className="progress-bar bg-success" role="progressbar"
                                                            aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "77%" }}>
                                                            <span className="sr-only">77% Complete</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-xl-3 col-md-6">
                                            <div className="card-box">
                                                <div className="dropdown float-right">
                                                    <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="javascript:void(0);" className="dropdown-item">odeme list</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">odeme ekle</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Yenile</a>
                                                    </div>
                                                </div>

                                                <h4 className="header-title mt-0 mb-3">Odeme</h4>

                                                <div className="widget-box-2">
                                                    <div className="widget-detail-2 text-right">
                                                        <span className="badge badge-danger badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                        <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                        <p className="text-muted mb-3">Odedi</p>
                                                    </div>
                                                    <div className="progress progress-bar-alt-success progress-sm">
                                                        <div className="progress-bar bg-danger" role="progressbar"
                                                            aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "77%" }}>
                                                            <span className="sr-only">77% Complete</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6">
                                            <div className="card-box">
                                                <div className="dropdown float-right">
                                                    {/* <a href="#" className="dropdown-toggle arrow-none card-drop" data-toggle="dropdown" aria-expanded="false">
                                                        <i className="mdi mdi-dots-vertical"></i>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        <a href="javascript:void(0);" className="dropdown-item">Liste des notifications</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Envoyer une notification</a>
                                                        <a href="javascript:void(0);" className="dropdown-item">Raffraichir</a>
                                                    </div> */}
                                                </div>

                                                <h4 className="header-title mt-0 mb-3">Ziyyaret</h4>

                                                <div className="widget-box-2">
                                                    <div className="widget-detail-2 text-right">
                                                        <span className="badge badge-pink badge-pill float-left mt-3">32% <i className="mdi mdi-trending-up"></i> </span>
                                                        <h2 className="font-weight-normal mb-1"> 0 </h2>
                                                        <p className="text-muted mb-3">Soular</p>
                                                    </div>
                                                    <div className="progress progress-bar-alt-pink progress-sm">
                                                        <div className="progress-bar bg-pink" role="progressbar"
                                                            aria-valuenow="77" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "77%" }}>
                                                            <span className="sr-only">77% Complete</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                        }
                    </div>
                    <Footer />
                </div>

            </div>
            <div className="rightbar-overlay"></div>
        </>
    )
};
export default Panel;