import React, { useState } from "react";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth/auth';
import Logo from "../../assets/images/logo-sm.png";

const Navbar = () => {

    const Dispacth = useDispatch();

    const homedata = useSelector(state => state.HomeReducer.homedata);
    const payement = useSelector(state => state.HomeReducer.payement); 

    const [semt, setSemt] = useState(false)
    const [ilan, setIlan] = useState(false)
    const [iletisim, setIletisim] = useState(false)

    
    const handleSemt = () => {
        setSemt(!semt)
        setIlan(false)
        setIletisim(false)
    }
    const handleIlan = () => {
        setIlan(!ilan)
        setSemt(false)
        setIletisim(false)
    }

    const handleIletisim = () => {
       setIletisim(!iletisim)
       setIlan(false)
       setSemt(false)
    }
    

    return (
        <div className="left-side-menu" style={{ overflow: "scroll" }}>
            <div className="slimscroll-menu">
                <div className="user-box text-center">
                    <img src={Logo} alt="user-img" title="Mat Helme" className="rounded-circle img-thumbnail avatar-lg" />
                    <div className="dropdown">
                        <a href="#" className="text-dark dropdown-toggle h5 mt-2 mb-1 d-block" data-toggle="dropdown">Yonetıcı Panel</a>
                        <a href="#" className="text-dark dropdown-toggle h5 mt-2 mb-1 d-block" data-toggle="dropdown">Admin</a>
                        <div className="dropdown-menu user-pro-dropdown">
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-user mr-1"></i>
                                <span>Hesabim</span>
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-settings mr-1"></i>
                                <span>Ayarlar</span>
                            </a>
                            <a href="javascript:void(0);" className="dropdown-item notify-item">
                                <i className="fe-lock mr-1"></i>
                                <span>Ekran kılıt</span>
                            </a>
                            <a onClick={() => Dispacth(logOut())} style={{cursor:"pointer"}} className="dropdown-item notify-item">
                                <i className="fe-log-out mr-1"></i>
                                <span>Çikiş</span>
                            </a>
                        </div>
                    </div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="#" className="text-muted">
                                <i className="mdi mdi-settings"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a onClick={() => Dispacth(logOut())} className="text-custom">
                                <i className="mdi mdi-power"></i>
                            </a>
                        </li>
                    </ul>
                </div>

               
                   
                    <div id="sidebar-menu" >
                    <ul className="metismenu" id="side-menu">
                        <li className="menu-title">Navigation</li>
                        <li>
                            <Link to="/">
                                <i className="mdi mdi-view-dashboard"></i>
                                <span> Ana Sayfa </span>
                            </Link>
                        </li>
                        <li >
                            <a onClick={handleSemt} href="javascript: void(0);">
                                <i className="mdi mdi-invert-colors"></i>
                                <span> Semt </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    semt ?
                                        <>
                                            <li><Link to="/add-semt">Semt ekle</Link></li>
                                            <li><Link to="/get-semt">Semt Listesi</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                        </li>

                        <li >
                            <a onClick={handleIlan} href="javascript: void(0);">
                                <i className="mdi mdi-invert-colors"></i>
                                <span> İlan </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    ilan ?
                                        <>
                                            <li><Link to="/add-ilan">Ilan ekle</Link></li>
                                            <li><Link to="/get-ilan">Ilan Listesi</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                         </li>

                         <li >
                            <a onClick={handleIletisim} href="javascript: void(0);">
                                <i className="mdi mdi-invert-colors"></i>
                                <span> İletişim </span>
                                <span className="menu-arrow"></span>
                            </a>
                            <ul className="nav-second-level" aria-expanded="false">
                                {
                                    iletisim ?
                                        <>
                                            <li><Link to="/iletisim">İletişim</Link></li>
                                        </>
                                        :
                                        null
                                }
                            </ul>
                         </li>
                    </ul>
                </div>
                
                <div className="clearfix"></div>
            </div>
        </div>
    )
};
export default Navbar;